// import $ from 'jquery';
window.$ = window.jQuery = $;
import './style.scss';



import Router from './util/Router';
import Cookie from './js/cookieinfo.min';
import Form from './js/form';
import ModernizrCustom from './js/modernizr-custom';
import common from './routes/common';

const routes = new Router({
    /** All pages */
    common
    /** Home page */
    // home,
    // /** About us page */
    // page
    // /** About Us page, note the change from about-us to aboutUs. */
});

/** Load Events */
$(document).ready(() => routes.loadEvents());