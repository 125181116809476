$(document).ready(function () {
    $('.main-form , .mobile-form').on('submit', function () {
        var form = $(this);
        $.ajax({
            type: "POST",
            url: form.attr('action'),
            data: form.serialize(),
            dataType: "JSON",
            success: function (response) {
                if (response.success) {
                    form.hide();
                    form.next('.form-message').show();
                    if (form.data('type') == 'popup') {
                        setTimeout(function () {
                            $(this).removeClass('active');
                            $('.popup-overlay').removeClass('active');
                            $('.form-popup').removeClass('active');
                            $('.index-work-form').removeClass('active');
                        }, 2000);
                    } else {

                    }
                }
            }
        });
        return false;
    });
});