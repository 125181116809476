export default {
    init() {
    },
    finalize() {
        if ($(window).width() > 980) {
            var lFollowX = 0,
                lFollowY = 0,
                x = 0,
                y = 0,
                friction = 1 / 30;

            function moveBackground() {
                x += (lFollowX - x) * friction;
                y += (lFollowY - y) * friction;

               var translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

                $('.image-box').css({
                    '-webit-transform': translate,
                    '-moz-transform': translate,
                    'transform': translate
                });

                window.requestAnimationFrame(moveBackground);
            }

            $(window).on('mousemove click', function (e) {

                var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
                var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
                lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
                lFollowY = (10 * lMouseY) / 100;
            });
            moveBackground();

            $(function () {
                var $el = $('.image-box');
                $(window).on('scroll', function () {
                    var scroll = $(document).scrollTop();
                    $el.css({
                        'background-position': '50% ' + (-.2 * scroll) + 'px'
                    });
                });
            });
        }

        $('.nav-mobile-button').on('click', function () {
            $(this).toggleClass('open');
            $('.mobile-menu-box').toggleClass('open');
        });
        if ($(window).width() < 960) {
            var quoteLength = $('.column').length;
            if (quoteLength > 1) {
                $('.column').next().hide();
            }
            $('.show-more').on('click', function (e) {
                e.preventDefault();
                $('.column').show();
            });
        } else {
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                if (scroll >= 95) {
                    $("#header").addClass("dark-header");
                } else {
                    $("#header").removeClass("dark-header");
                }
            });
            if (window.pageYOffset > 95) {
                $("#header").addClass("dark-header");
            } else {
                $("#header").removeClass("dark-header");
            }
        }
        $('.mobile-menu-box .navigation-link').on('click', function () {
            $('.mobile-menu-box').removeClass('open');
            $('.nav-mobile-button').removeClass('open');
        });
        $('.popup-button').on('click', function () {
            $('.popup-overlay').addClass('active');
            $('#subpages-form').addClass('active');
        });
        $('.request-form').on('click', function (e) {
            e.preventDefault();
            $('.index-work-form').addClass('active');
            $('#footer-request-form').addClass('active');
        });
        $('.popup-overlay , .index-work-form').on('click', function () {
            $('#footer-request-form').removeClass('active');
            $(this).removeClass('active');
            $('#subpages-form').removeClass('active');
        });
        $('.mobile-products-button').on('click', function (e) {
            e.preventDefault();
            $('.dropped').slideToggle(500);
        });
        $('.form-select').select2();
        Modernizr.on('webp', function (result) {
            if (result) {
                // supported
            } else {
                // not-supported
            }
        });
    }
};


